import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`

  body {
    --color-twitter: #55acee;
    --color-instagram: #517fa4;
    --color-feed: #fa9b39;
    --color-mute: #9ea1a3;
    --color-divider: #e5e2e4;
    --color-accent: #e0b131;
    --color-primary: #2f7d95;
    --color-secondary: #235e70;
    --color-font: #000;
    --color-bg: #fff;
    --color-label-bg: #f6f8fa;
    --color-label-fg: var(--color-font);
    --color-nav-fg: var(--color-font);
    --color-header-fg: #646769;
    --color-header-bg: #f2f3f3;
    --color-footer-fg: #646769;
    --color-footer-bg: #f2f3f3;
    --switch-icon: "🌚";
  }

  body.dark-mode {
    --color-primary: #8cd2d5;
    --color-secondary: #a9dde0;
    --color-font: #eaeaea;
    --color-bg: #252a34;
    --color-label-bg: #f6f8fa;
    --color-label-fg: var(--color-bg);
    --color-nav-fg: var(--color-font);
    --color-header-fg: var(--color-font);
    --color-header-bg: var(--color-bg);
    --color-footer-fg: var(--color-font);
    --color-footer-bg: #1a1d24;
    --switch-icon: "🌝";
  }

  body {
    margin: 0;
    padding: 0;
    background-color: var(--color-bg);
    color: var(--color-font);
  }

  body.dark-mode a.logo {
    background: url('/logo_light.svg') no-repeat;
  }

  body.light-mode a.logo {
    background: url('/logo_dark.svg') no-repeat;
  }

  a {
    color: var(--color-primary);
    text-decoration: underline;
  }

  a:hover {
    color: var(--color-secondary);
    text-decoration: underline;
  }

  img {
    margin-bottom: 0;
    max-width: 100%;
  }

  ul, ol {
    list-style: none;
    margin: 0;
    padding: 0;
  }
`;

export default GlobalStyles;
