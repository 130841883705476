import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const StyledLogo = styled.div`
  text-align: center;

  a {
    display: inline-block;
    width: 350px;
    height: 150px;
  }
`;

export default function Logo() {
  return (
    <StyledLogo>
      <Link to="/" className="logo" />
    </StyledLogo>
  );
}
