import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Container from '../Layout/Container';

const StyledNav = styled.nav`
  border-top: 1px solid var(--color-font);
  border-bottom: 1px solid var(--color-font);
  padding: 1rem;
`;

const StyledNavMenu = styled.ol`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  li {
    padding: 0.5rem 1rem;
    margin: 0;
  }

  a {
    color: var(--color-fg);
    text-transform: uppercase;
    text-decoration: none;
  }

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export default function NavMenu() {
  return (
    <Container>
      <StyledNav id="nav-menu">
        <StyledNavMenu>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/recetario">Recetario</Link>
          </li>
          <li>
            <Link to="/quienes-somos">Equipo</Link>
          </li>
          <li>
            <Link className="search" to="/buscador">Buscador</Link>
          </li>
        </StyledNavMenu>
      </StyledNav>
    </Container>
  );
}
