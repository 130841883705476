import React from 'react';
import styled from 'styled-components';
import useDarkMode from 'use-dark-mode';

const StyledThemeToggler = styled.label`
  &:before {
    content: var(--switch-icon);
    font-size: 2rem;
  }

  input {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  }
`;

export default function DarkModeToggler() {
  const darkMode = useDarkMode(false);

  return (
    <StyledThemeToggler>
      <input
        type="checkbox"
        name="theme-switch"
        onChange={darkMode.toggle}
        checked={darkMode.value}
      />
    </StyledThemeToggler>
  );
}
