import React from 'react';
import { FaTwitter, FaInstagram, FaRss } from 'react-icons/fa';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Container from '../Layout/Container';

const StyledFooter = styled.footer`
  padding: 1.5rem 0;
  background-color: var(--color-footer-bg);
  color: var(--color-footer-fg);

  .fa-twitter {
    color: var(--color-twitter);
  }

  .fa-instagram {
    color: var(--color-instagram);
  }

  .fa-feed {
    color: var(--color-feed);
  }


  a {
    color: var(--color-footer-fg);
  }

  p {
    font-size: .6875rem;
  }

  ul.social {
    li {
      display: inline-block;
      padding-top: 5px;
      padding-bottom: 5px;
      font-size: .75em;
      text-transform: uppercase;
    }

    li:not(:last-child) {
      padding-right: 10px;
    }
  }
`;

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <StyledFooter>
      <Container>
        <ul className="social">
          <li>
            <a href="https://twitter.com/unaderecetas" alt="Twitter de Unaderecetas">
              <FaTwitter className="fa-twitter" /> Twitter
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/unaderecetas" alt="Instagram de Unaderecetas">
              <FaInstagram className="fa-instagram" /> Instagram
            </a>
          </li>
          <li>
            <Link to="/rss.xml"><FaRss className="fa-feed" /> Rss</Link>
          </li>
        </ul>
        <p>&copy; {currentYear} Unaderecetas. Cocinado a fuego lento en Barcelona</p>
      </Container>
    </StyledFooter>
  );
}
