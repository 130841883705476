import React from 'react';
import styled from 'styled-components';
import DarkModeToggler from '../Elements/DarkModeToggler';

const StyledWidgets = styled.div`
  text-align: right;
  padding: 0.5rem 1rem;
`;

export default function Widgets() {
  return (
    <StyledWidgets>
      <DarkModeToggler />
    </StyledWidgets>
  );
}
