exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-buscador-js": () => import("./../../../src/pages/buscador.js" /* webpackChunkName: "component---src-pages-buscador-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-quienes-somos-js": () => import("./../../../src/pages/quienes-somos.js" /* webpackChunkName: "component---src-pages-quienes-somos-js" */),
  "component---src-pages-recetario-js": () => import("./../../../src/pages/recetario.js" /* webpackChunkName: "component---src-pages-recetario-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/Category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-recipe-js": () => import("./../../../src/templates/Recipe.js" /* webpackChunkName: "component---src-templates-recipe-js" */)
}

