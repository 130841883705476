import React from 'react';

import 'lazysizes';
import 'normalize.css';
import GlobalStyles from '../../styles/GlobalStyles';

import Widgets from '../Header/Widgets';
import Logo from '../Header/Logo';
import Nav from '../Header/Nav';
import Footer from '../Footer/Footer';

export default function Layout({ children }) {
  return (
    <main role="main">
      <GlobalStyles />
      <Widgets />
      <Logo />
      <Nav />
      <div className="content">{children}</div>
      <Footer />
    </main>
  );
}
